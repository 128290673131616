<template>
<div>

<div style="width: 100%; height: 91px" class="display_none_block_hello_world"></div>
    <div id="header">
      <transition name="slide-fade">
        <b-navbar type="light" variant="light" v-if="showw" style="position: fixed; width: 100%;z-index: 1000; top: 0px;">
          <b-navbar-nav style="    justify-content: space-between;
    width: 100%;">
            <b-navbar variant="faded" type="light">
              <b-navbar-brand href="#" class="logo">
                <img
                  src="../assets/logotip.png"
                  style="width: 40px; display: inline-block"
                  alt=""
                />
                <h2 class="log">roobo.pro</h2>
              </b-navbar-brand>
            </b-navbar>

            <!-- <b-nav-item class="intro firs" href="https://roobo.pro"
            >Главная</b-nav-item
          > -->
            <!-- <a class="head" href="https://roobo.pro/">Главная</a>

            <b-nav-item-dropdown class="intro" text="Каталог" right>

              <router-link class="head" to="/productcatalogue"
                >Каталог Товаров</router-link
              >
            </b-nav-item-dropdown>

            <router-link class="head" to="/action">Акции</router-link>
            <router-link
              class="head"
              to="/topsubscriptions"
              style="padding-top: 10px"
              >Топ 10<br />подписок</router-link
            >
            <router-link
              class="head"
              to="/topproducts"
              style="padding-top: 10px"
              >Топ 10<br />товаров</router-link
            >
            <router-link class="head" to="/vip">VIP</router-link> -->

            <div style="display: flex;margin-right: 45px;">
              <a :href="'https://roobo.pro'+'/mysubs'" class="head" v-if="user.login" style="text-decoration: none; padding-top: 10px;">Мои
                <br> подписки</a>
              <!-- <b-nav-item class="intro" href="https://roobo.pro/action"
              >Акции</b-nav-item
            >
            <b-nav-item href="https://roobo.pro/topsubscriptions"
              >Топ 10 подписок</b-nav-item
            >
            <b-nav-item href="https://roobo.pro/topproducts"
              >Топ 10 подписок</b-nav-item
            >
            <b-nav-item class="intro" href="https://roobo.pro/vip"
              >VIP</b-nav-item
            > -->
              <div class="registrated" v-if="user.id">
                <registrated :user="user" />
              </div>
              <div class="author" v-else>
                <auth />
              </div>
              <div class="becomeauthor" v-if="user.status == 'user'">
                <becomeauthor />
              </div>
              <b-nav-item>
              </b-nav-item>
            </div>


          </b-navbar-nav>
        </b-navbar>
      </transition>
      <b-modal id="modal-complain">
        <template #modal-header="">
          <!-- Emulate built in modal header close button action -->
          <h5>Жалоба</h5>
        </template>

        <template #default="">
          <h5>Введите причину жалобы</h5>
          <b-form-textarea
            placeholder="Введите вашу жалобу"
            style="overflow-y: hidden"
            no-resize
            rows="3"
            max-rows="8"
            v-model="compl"
          >
          </b-form-textarea>
          <p>Колличество символов: {{lenght}}</p>
          <p class="wwarning">
            {{ err }}
          </p>
        </template>

        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            style="margin-right: 10px"
            variant="primary"
            v-on:click="sendinsult()"
            >Отправить жалобу</b-button
          >
          <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
          <!-- Button with custom close trigger value -->
        </template>
      </b-modal>
    </div>
    <nav role="navigation">
  <div id="menuToggle">

    <input type="checkbox" />

    <span></span>
    <span></span>
    <span></span>

    <ul id="menu">
      <div class="registrated" v-if="user.id">
              <registrated :user="user" />
            </div>
            <div class="author" v-else>
              <auth />
            </div>
            <div class="becomeauthor" v-if="user.status == 'user'">
              <becomeauthor />
            </div>

            <router-link style="
          display: flex;
          justify-content: flex-start;" class="head" to="/vip">VIP</router-link>
      <a :href="'https://roobo.pro'+'/mysubs'" class="head" v-if="user.login"  style="
          display: flex;
          justify-content: flex-start;
text-decoration: none;">Мои подписки</a>
    </ul>
  </div>
</nav>
</div>
</template>


<script>
import Api from "../api";
import auth from "./auth.vue";
import registrated from "@/components/registrated.vue";
import becomeauthor from "@/components/becomeauthor.vue";
export default {
  name: "HelloWorld",
  components: { auth, registrated, becomeauthor },
  props: {
    user: Object,
    meow: String,
  },
  data() {
    return {
      showw: false,
      compl: "",
      err: "",
    };
  },

  methods: {
    async sendinsult() {
      let strr = JSON.parse(localStorage.getItem("complain"));
      const json = {
        description: this.compl,
        type: strr.type,
        id_obj: strr.id,
      };
      const result = await Api.users.sendinsult(
        json,
        localStorage.getItem("token")
      );
      if (result.message) {
        this.err = result.message;
      } else {
        this.$bvModal.hide("modal-complain");
      }
      console.log(result);
    },
  },
  async mounted() {
    setTimeout(() => {
      this.showw = true;
    }, 100);
    if (this.meow) {
      document.getElementById("header").classList.add("bar");
    }
  },
  computed: {
    lenght: function () {
      return this.compl.length;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  margin: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#menuToggle {
  display: none;
}
// .navcc{
// position: absolute;
//     left: 20px;
//     top: 10px;
//     z-index: 1000;
//     text-align: left;
//     width: 60px;
//     font-size: 30px;
// }
.intro {
  font-family: Arial, sans-serif;
  font-weight: 500;
  margin-top: 8px;
  font-size: 20px;
}
.head {
  display: inline-block;
  color: rgba(0, 0, 0, 0.55);
  font: 20px Arial, sans-serif;
  margin-right: 10px;
  text-decoration: none;
  vertical-align: middle;
  padding: 19px 25px;
}
.log {
  display: inline-block;
}
.head:focus {
  color: rgba(0, 0, 0, 0.7);
}
.logo {
  margin-left: 30px;
  margin-right: 3vw;
}
.firs {
  margin-left: 20%;
}
.navigation {
  display: none;
}
.nav {
  padding: 0;
}
.author {
  position: absolute;
  top: 8px;
  right: 20px;
}
.foradmin {
  padding-top: 12px;
}
.registrated {
  margin-left: 1rem;
}
.bar {
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all 1.1s ease;
}
.slide-fade-leave-active {
  transition: all 1.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(5px);
  opacity: 0;
}

@media only screen and (max-width: 1395px) {
  .head {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1333px) {
  .head {
    padding: 19px 20px;
  }
}
@media only screen and (max-width: 1272px) {
  .head {
    padding: 19px 15px;
  }
}
@media only screen and (max-width: 1210px) {
  li {
    margin: 0;
  }
}
@media only screen and (max-width: 1188px) {
  .registrated {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1170px) {
  .head {
    padding: 19px 13px;
  }
}

@media only screen and (max-width: 1150px) {
  .display_none_block_hello_world {
    display: none;
  }
  #header {
    display: none;
  }
  #headermobile {
    display: block;
    color: #f2f3f4;
  }
  .b-sidebar-body {
    display: grid;
  }
  #menuToggle {
    display: block;
    position: relative;
    top: 30px;
    left: 50px;
    height: 80px;
    z-index: 33;

    -webkit-user-select: none;
    user-select: none;
  }
  .navigation {
    display: block;
    height: 80px;
  }
  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }
  #menu {
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -50px;
    padding: 115px 20px 20px 20px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  // #menu li
  // {
  //   padding: 10px 0;
  //   font-size: 22px;
  // }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .head {
    display: flex;
    color: rgba(0, 0, 0, 0.55);
    font: 20px Arial, sans-serif;
    margin-right: 10px;
    text-decoration: none;
    vertical-align: middle;
  }
  .author {
    position: relative;
    padding-left: 27px;
  }
  .authorr {
    width: 240px;
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 15px;
    grid-gap: 10px;
  }
}
</style>

